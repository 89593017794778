import { mapActions, mapGetters, mapMutations } from 'vuex'
import dateLocale from '@/services/dateLocale'
import status from '@/services/status'

export default {
  computed: {
    filteredTasks () {
      if (!this.filter) {
        return this.tasks
      }
      return this.applyFilterOnTasks(this.tasks)
    },
    formattedTasks () {
      //return this.filteredTasks.map((t) => {
      return this.tasks.map((t) => {
        return {
          ...t,
          soon: this.taskIsSoon(t),
          late: this.taskIsLate(t),
        }
      })
    },
    calendarFormattedTasks () {
      return this.formattedTasks.map((t) => {
        return {
          ...t,
          id: t.tsk_id,
          name: this.getShortTaskName(t),
          start: this.$date(t.tsk_started_at).format('YYYY-MM-DD'),
          end: this.$date(t.tsk_ended_at).format('YYYY-MM-DD'),
          color: this.defineCalendarTaskColor(t),
          timed: false
        }
      })
    },
    ...mapGetters({
      types: 'app/types',
      states: 'app/states',
      filter: 'app/filter',
      filterToUrl: 'app/filterToUrl',
      permissions: 'user/permissions'
    })
  },
  methods: {
    taskIsSoon (task) {
      return task.tsk_sta_id === status.ACCEPTED
        && this.$date(task.tsk_started_at).isAfter(this.$date())
        && this.$date().add(process.env.VUE_APP_NB_DAYS_WARNING , 'days').isAfter(this.$date(task.tsk_started_at))
    },
    taskIsLate (task) {
      return task.tsk_sta_id === status.ACCEPTED
        && this.$date(task.tsk_started_at).isBefore(this.$date(), 'days')
    },
    defineCalendarTaskColor (task) {
      let color = ''
      switch (task.tsk_sta_id) {
        case status.FINISHED:
          color = 'finished'
          break
        case status.ACCEPTED:
          if (task.late) {
            color = 'error'
          } else if (task.soon) {
            color = 'warning'
          } else {
            color = 'scheduled'
          }
          break
        case status.ASKED:
          color = 'asked'
          break
      }
      return color
    },
    defineTaskClass (task) {
      const allClass = ['']
      switch (task.tsk_sta_id) {
        case status.FINISHED:
          allClass.push('finished')
          break
        case status.ACCEPTED:
          if (task.late) {
            allClass.push('error')
          }
          if (task.soon) {
            allClass.push('warning')
          }
          break
        case status.ASKED:
          allClass.push('asked')
          break
      }
      return allClass.join(' ')
    },
    findTypeById (id) {
      return this.types.find(type => type.typ_id === id)
    },
    applyFilterOnTasks (tasks) {
      return [...tasks].filter((task) => {
        const taskTypes = task.types.map((t) => {
          return t.typ_id
        })
        const fillsCustomCondition = (this.filter?.custom?.length) ? this.filter.custom.includes(!!task.tsk_custom) : true
        const fillsActionsCondition = (this.filter?.actions?.length) ? this.filter.actions.every(action => {
          return taskTypes.includes(action)
        }) : true
        const fillsAfterStartDateCondition = (this.filter?.start) ? this.$date(task.tsk_started_at)
          .isAfter(this.$date(this.filter.start)) : true
        const fillsBeforeEndDateCondition = (this.filter?.end) ? this.$date(task.tsk_started_at)
          .isBefore(this.$date(this.filter.end)) : true
        const fillsStateCondition = (this.filter?.states?.length) ? this.filter.states.includes(task.tsk_sta_id) : true
        return fillsCustomCondition && fillsActionsCondition && fillsAfterStartDateCondition && fillsBeforeEndDateCondition && fillsStateCondition
      })
    },
    getTotalTime (task) {
      const timesheet = this.buildTimesheetFromTask(task)
      let nb = 0
      timesheet.forEach(row => nb += row?.time || 0)
      return `${nb} h`
    },
    getEditableTask (task) {
      return {
        id: task.tsk_id,
        start: dateLocale.formatDate(task.tsk_started_at),
        end: dateLocale.formatDate(task.tsk_ended_at),
        actions: task.types.map(action => action.typ_id),
        comment: task.tsk_obs,
        custom: task.tsk_custom ? 1 : 0,
        state: task.tsk_sta_id,
        users: task.users.map(user => user.usr_id),
        timesheet: this.buildTimesheetFromTask(task),
        original: task,
        files: task.files
      }
    },
    getViewableTask (task) {
      return {
        id: task.tsk_id,
        start: dateLocale.formatDate(task.tsk_started_at),
        end: dateLocale.formatDate(task.tsk_ended_at),
        actions: task.types,
        comment: task.tsk_obs,
        custom: task.tsk_custom ? 1 : 0,
        state: task.state,
        users: task.users,
        timesheet: this.buildTimesheetFromTask(task),
        original: task,
        files: task.files
      }
    },
    buildTimesheetFromTask (task) {
      return task.types.map((action) => {
        const type = this.findTypeById(action.typ_id)
        return {
          id: action.typ_id,
          type,
          time: action?.pivot?.tkt_time || 0
        }
      })
    },
    getTaskPayload (task) {
      return {
        ...task,
        comment: task.comment,
        custom: !!task.custom,
        timesheet: task.timesheet.map(row => ({ id: row.id, time: row.time })),
        picture: task.picture
      }
    },
    getTaskName (task) {
      const labels = task?.types?.map((t) => t.typ_label)
      const name = this.getShortTaskName(task)
      return labels.length ? `${name}: ${labels?.join(', ')}` : name
    },
    getTaskActionsLabel (task) {
      const labels = task?.types?.map((t) => t.typ_label)
      return labels.length ? `${labels?.join(', ')}` : '-'
    },
    getShortTaskName (task) {
      return `Tâche #${task.tsk_id}`
    },
    taskCanBeAccepted (task) {
      return task.state.sta_id === status.ASKED
    },
    taskCanBeFinished (task) {
      return task.state.sta_id === status.ACCEPTED
    },
    ...mapMutations({
      setTask: 'tasks/SET_TASK',
      removeTask: 'tasks/REMOVE_TASK'
    }),
    ...mapActions({
      fetchTask:  'tasks/fetchTask',
      fetchTasks: 'tasks/fetchTasks',
      fetchTasksByArea: 'tasks/fetchTasksByArea'
    })
  }
}
